import React from "react";
import './App.css';
import linkedin from './images/linkedin-96.png'
import github from './images/github-128.png';
import projects from './images/projects-100.png'
import Projects from "./Projects";

function Home() {
    return (
        <div className="App">
        <h2 className='name'>Brad Morris</h2>
        <hr></hr>
        <div className='about'>
          <p>
            I'm an ex-Marketer turned software developer. I worked at companies like Salesforce, Zendesk, and Slack for 15 years. After a long break from software I got the itch to learn to build it so I attended <a href="https://www.bloomtech.com" >BloomTech</a>.
          </p>
          <p>
            I'm looking for my first developer gig. I’d love to join a smart team working on a hard problem. My experience is with Node, React, Express, Redux, PostgreSQL, and similar technologies, but I'm ready and willing to learn anything.
            <div className="profiles">
              <a href='https://www.linkedin.com/in/cbradmorris/'><img src={linkedin} className='linkedin-icon' /></a>
              <a href='https://github.com/attyfinch'><img src={github} className='github-icon' /></a>
            </div>
          </p>

        </div>
        <Projects />
        </div>
    );
};

export default Home;