import React from "react";

export default function Projects() {
    return (
        <div className="projects-section">
            <div className="projects-section-header">Some things I've built...</div>
            <div className="project">
                <div className="project-header">
                    <div className="project-title">▪️ WordleAssist</div>
                    <div className="project-details">
                        <a href="https://wordleassist.dev">Website</a>
                        <div>|</div>
                        <a href="https://github.com/attyfinch/wordle-assist">GitHub</a></div>
                </div>
                <div className="project-description">
                    A simple app that helps solve Wordle Puzzles. It's built with Node, React, Knex, Axios, and powered by the Words API (see below).
                </div>
            </div>
            <div className="project">
                <div className="project-header">
                    <div className="project-title">▪️ Words API</div>
                    <div className="project-details">
                        <a href="https://github.com/attyfinch/words-api">GitHub</a>
                    </div>
                </div>
                <div className="project-description">
                    An API I built with Express and PostgreSQL. POST requests containing Wordle guesses return a list of ranked words that are possible answers. This is a WIP. My long term plan is for this to be a public API that powers use cases beyond WordleAssist.
                </div>
            </div>
        </div>
    )
}