import React from 'react';
import Home from './Home';
import Projects from './Projects';
import { Routes, Route, Link } from 'react-router-dom';


function App() {
  return (
    <div>
      <Routes>
        <Route exact path="/" element={<Home />}/>
        {/* <Route exact path="/projects" element={<Projects/>} /> */}
      </Routes>
    </div>
  );
}

export default App;
